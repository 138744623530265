@use '@angular/material' as mat;
@import "app-theme.scss";
$warning-height: 150px;

html,
body {
    margin: 0;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0 !important;
    height: 100%;
    font-size: 15px !important;
    background-color: #F9F3E8;
}

body {
    font-family: 'Raleway', sans-serif;
}

@media only screen and (min-width: 1027px) {
    html{
        font-size: 18px !important;
    }
}

@media only screen and (max-width: 1026px) {
    html{
        font-size: 15px !important;
    }
}

h1.Title {
    font-size: 1.8rem;
    font-weight: 700;
}

h2.Title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 15px;
}

h3.Subtitle {
    font-size: 1.3rem;
    line-height: 1.8rem;
    font-weight: 500;
    padding-top: 5px;
    color: #717174;
}

h4.Subtitle {
    font-size: 1rem;
    font-weight: 700;
    padding-top: 20px;
    color: #717174;
    text-transform: uppercase;
}

p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400 !important;
}

// Right-to-left language styles
html[dir='rtl'] {
    //TODO
}

.StandardCaps {
  text-transform: none !important;
}

.LegalAgePrompt {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400 !important;
    color: #333 !important;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: auto !important;
}

.Accent {
    font-weight: 500 !important;
}

html[dir='rtl'] .Accent {
    font-weight: 600 !important;
}

.Italic {
    font-weight: 300 !important;
    font-style: italic;
}

a,
button {
    text-decoration: none !important;
    outline: 0 !important;
}

a:hover,
a:active,
a:focus {
    outline: 0;
}

.Underline {
  text-decoration: underline;
}

//Button styles
.Button {
    border-radius: 2px;
    font-weight: 500;
    font-size: 1rem;
    padding: 12px 30px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;
    margin-top: 5px;
}

.Button--primary,
.Button--primary:active,
.Button--primary:focus {
    border: 1px solid #82B547 !important;
    background-color: #82B547 !important;
    color: #ffffff !important;
}

.Button--primaryYellow,
.Button--primaryYellow:active,
.Button--primaryYellow:focus {
    border: 1px solid #F2B816 !important;
    background-color: #F2B816 !important;
    color: #ffffff !important;
}

.CookieButton--Reject,
.CookieButton--Reject:active,
.CookieButton--Reject:focus {
  border: 1px solid #F2B816 !important;
  background-color: transparent !important;
  color: #F2B816 !important;
}

.CookieButton--Accept,
.CookieButton--Accept:active,
.CookieButton--Accept:focus {
  border: 1px solid #F2B816 !important;
  background-color: #F2B816 !important;
  color: #000000;
}

.CookieButton--Preferences,
.CookieButton--Preferences:active,
.CookieButton--Preferences:focus {
  width: 200px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
  border: none;
  background-color: transparent;

  &:hover,
  &:active {
    color: #a88838;
  }
}

.Button--primaryNeutral,
.Button--primaryNeutral:active,
.Button--primaryNeutral:focus {
    border: 1px solid #9E9E9E !important;
    background-color: #9E9E9E !important;
    color: #ffffff !important;
}

.Button--primaryNeutralWithFocus,
.Button--primaryNeutralWithFocus:active {
  border: 2px solid #9E9E9E !important;
  background-color: #9E9E9E !important;
  color: #ffffff !important;
}

.Button--primaryNeutralWithFocus:focus {
  border: 2px solid #F2B816 !important;
}

.Button--primaryGoogle {
    border: 1px solid #db4437 !important;
    background-color: #db4437 !important;
    color: #ffffff !important;
}

.Button--primaryDarkYellow,
.Button--primaryDarkYellow:active,
.Button--primaryDarkYellow:focus {
    margin-top: 20px;
    border-radius: 3px;
    background-color: #94752a !important;
    border: 1px solid #94752a;
    color: #F3D06F !important;
}

.Button--primaryDarkYellow:hover {
    box-shadow: 1px 1px 4px black !important;
}

.Button:hover {
    box-shadow: 1px 1px 5px #9E9E9E;
}

.Button--secondaryGreen,
.Button--secondaryGreen:active,
.Button--secondaryGreen:focus,
.Button--secondaryGreen:hover {
    background-color: transparent;
    border: 2px solid #82B547;
    color: #82B547;
}

.Button--secondaryNeutral,
.Button--secondaryNeutral:active,
.Button--secondaryNeutral:focus {
    background-color: transparent;
    border: 2px solid #9E9E9E;
    color: #9E9E9E;
}

.Button--secondaryWhite,
.Button--secondaryWhite:active,
.Button--secondaryWhite:focus {
    border-radius: 3px;
    background-color: transparent !important;
    border: 2px solid #ffffff;
    color: #ffffff !important;
}

.Button--right {
    float: right !important;
    margin: 0 0 0 15px;
}

.Button--right .Icon {
    padding: 0 0 0 5px;
    font-size: 0.8rem;
}

.Button--left {
    float: left !important;
    margin: 0 15px 0 0;
}

.Button--left .Icon {
    padding: 0 5px 0 0;
    font-size: 0.8rem;
}

.Button--active {
    font-weight: 700;
    color: #a88838 !important;
    border: 2px solid #a88838 !important;
}

.ButtonFilled {
    border: 1px solid #F2B816;
    border-radius: 2px;
    font-weight: 500;
    font-size: 1rem;
    padding: 12px 30px;
    margin-left: 15px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-align: center;
    margin-top: 5px;
    outline: none;
    color: mat.get-color-from-palette($app-theme, 100);
    background-color: mat.get-color-from-palette($app-theme, 600);
}

//Next button
#nextButton {
    color: #82B547 !important;
    border-color: #82B547 !important;
}

//Previous button
#prevButton {
    color: #9E9E9E !important;
    border-color: #9E9E9E !important;
    min-width: unset !important;
}

//Consent submit button and back to dashboard button
#submitButton, #closeButton {
    background-color: #82B547 !important;
    border-color: #82B547 !important;
    padding: 7px 30px !important;
    font-weight: 500 !important;
    box-shadow: none;
    border-radius: 2px !important;
    border-width: 2px !important;
    border-style: solid !important;
    color: #FFFFFF !important;
}

//Next button and previous button
#nextButton,  #prevButton{
    background: transparent !important;
    padding: 7px 30px 7px 30px !important;
    font-weight: 500 !important;
    box-shadow: none;
    border-radius: 2px !important;
    border-width: 2px !important;
    border-style: solid !important;
}

.ButtonFilled:focus {
    outline: none;
}

.ButtonFilled:hover,
.ButtonFloating:hover,
.Button--rect:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
}

.ButtonFilled--white {
    background-color: mat.get-color-from-palette($app-theme, 100) !important;
    color: mat.get-color-from-palette($app-theme, 1500) !important;
    font-size: 0.95rem;
    margin-top: 20px;
}

.ButtonFilled--white:hover {
    background-color: mat.get-color-from-palette($app-theme, 100) !important;
}

.ButtonFilled--neutral {
    margin-right: 0;
}

.ButtonFilled--neutral--margin {
    margin-right: 20px;
}

.Button--rect {
    float: right;
    border-radius: 2px !important;
    display: inline-block;
    font-size: 0.95rem;
    font-weight: 300;
    vertical-align: middle;
    z-index: 1;
    transition: all 0.3s ease-out;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.Button--disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.ButtonBordered {
    padding: 7px 20px;
    border-radius: 18px;
    background-color: transparent;
    font-size: 1rem;
    font-weight: 700;
    border: 2px solid;
    transition: color 0.3s ease, border-color 0.3s ease;
}

.ButtonBordered:hover {
    text-decoration: none;
    background-color: transparent;
    color: mat.get-color-from-palette($app-theme, 700);
    border-color: mat.get-color-from-palette($app-theme, 700);
}

.Button--countMeIn{
    margin: 0 auto !important;
    text-align: center;
    max-width: 180px;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 6px 15px 7px 15px;
    border-radius: 22px;
    display: block;
}

.ButtonBordered--countMeInMoreMargin {
    margin-bottom: 70px !important;
}

.ButtonBordered--orange,
.ButtonBordered--orange:visited {
    color: mat.get-color-from-palette($app-theme, 600) !important;
    border-color: mat.get-color-from-palette($app-theme, 600) !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
    transition: all 0.3s ease-out;
}

.ButtonBordered--orange:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15) !important;
}

.ButtonBordered--white,
.ButtonBordered--white:visited {
    color: mat.get-color-from-palette($app-theme, 100);
    border-color: mat.get-color-from-palette($app-theme, 100);
}

.Button--cell {
    font-size: 0.85rem;
    min-width: 90px !important;
    height: 30px;
    line-height: 26px !important;
    margin: 8px 2px !important;
    padding: 0 !important;
    border-radius: 2px !important;
    outline: none;
}

@media only screen and (max-width: 767px) {
    .Button--cell {
        min-width: 95% !important;
    }
}

.CountButton {
    font-weight: 500;
    border: 2px solid mat.get-color-from-palette($app-theme, 100);
    padding: 8px 25px;
    margin: 5px 5px 5px 5px;
    color: mat.get-color-from-palette($app-theme, 100);
    border-radius: 3px;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
}


.MenuButton {
    display: inline-flex;
    text-decoration: none;
    color: mat.get-color-from-palette($app-theme, 100);
    cursor: pointer;
}

.SimpleButton {
    line-height: 3.2rem !important;
    background: none;
    border: none;
    padding: 0;
    outline: none;
    font-size: 1rem;
    transition: color 0.3s ease;
    cursor: pointer;
    font-weight: 500;
    text-decoration: none;
    color: mat.get-color-from-palette($app-theme, 100);
    margin-top: 5px;
}

.SimpleButton:hover {
    color: #a88838 !important;
    text-decoration: none;
}

.SimpleButton:active {
    text-decoration: none;
    color: #a88838 !important;
}

.SpacedButtonDiv {
  padding-bottom: 30px;
}

// End of button styling section

// Language selector styles
.SimpleButton .ddp-globe {
    stroke: mat.get-color-from-palette($app-theme, 100);
    fill: transparent;
    vertical-align: middle;
    transition: stroke 0.3s ease;
}

.SimpleButton:hover .ddp-globe {
    stroke: mat.get-color-from-palette($app-theme, 700);
    fill: transparent;
}

.SimpleButton:active .ddp-globe {
    stroke: mat.get-color-from-palette($app-theme, 600);
    fill: transparent;
}

ddp-language-selector .SimpleButton {
    margin-top: 0;
}

.ddp-current-language {
    vertical-align: middle;
}

mat-icon.ddp-dropdown-arrow {
    vertical-align: middle;
}

.mat-menu-content {
  padding: 10px !important;

  .mat-menu-item:first-of-type {
    line-height: 32px !important;
    padding-bottom: 16px !important;
  }

  .mat-menu-item:last-of-type {
    line-height: 32px !important;
    padding-top: 16px !important;
  }
}

.example-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.column2 {
    float: left;
    width: 50%;
}

.column3 {
    float: left;
    width: 33%;
}

@media (max-width: 600px) {
    .column2 {
        width: 100%;
    }
}

@media (max-width: 900px) {
    .column3 {
        width: 100%;
    }
}

.row {
    display: block;
}

.Left {
    display: flex;
    justify-content: flex-start;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.scroll-table-container {
    overflow: auto;
}

//Study Listing Table
//All filters (top and column filters)
input.mat-input-element.study-listing-filter {
  font-size: 14px;
  height: 34px !important;
  margin-top: 0;
}

.study-listing-filter {
 //TODO: Hebrew
}

.study-listing-filter-all-container {
    min-width: 660px;
}

.table--studyListing {
  table-layout: fixed;
  overflow-wrap: break-word;
  min-width: 720px;

  //Add color striping
  .filter-row, tbody>tr:nth-of-type(even) {
    background-color: #f9f9f9;
  }

  .header-row {
      th:nth-of-type(2) {
          width: 22%;
      }
      th:nth-of-type(3) {
          width: 15%;
      }
      th:nth-of-type(5) {
          width: 20%;
      }
  }

  //Make cell padding consistent
  th.mat-header-cell:first-of-type, td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type,
  th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type
  {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  //Table headers
  th {
    font-weight: bold;
    font-size: 15px;
    color: #333;
  }

  tr.mat-header-row {
    height: unset;
  }

  //Filter header row
  .study-listing-filter-col {
    width: auto;
  }

  .filter-cell {
    border-bottom-width: 1px;
  }

  //Table body
  td {
    display: table-cell;
  }

  .mat-cell {
    font-size: 15px;
  }

  dt {
    font-weight: 400;
  }
}

.ddp-dashboard {
  width: 100% !important;
  table-layout: fixed;
  overflow-wrap: break-word;

  th:nth-of-type(2) {
    width: 30%;
  }
}

.form-control {
  max-width: 100% !important;
}

.fill-remaining-space {
    flex: 1 1 auto;
}

.example-3col-card {
    height: 75px;
    padding: 15px;
    margin: 10px;
}

.example-2col-card {
    padding: 15px;
    margin: 10px;
}

// App Header
.Header {
    height: auto;
    width: 100%;
    z-index: 1000;
}

.Header--home {
    background: transparent !important;
    position: absolute;
}

.Header-background {
    position: relative;
    padding: 30px 80px 20px 80px;
    margin: 0;
    background: #F2B816 none;
    border-radius: 0;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 100;
    width: 100%;
}

.navbar-nav {
    float: right !important;
    width: 660px;
}

html[lang='es'] .navbar-nav {
    width: 800px;
}

@media only screen and (min-width: 1027px) and (max-width: 1200px) {
    html[dir='rtl'] .Header-background {
        padding: 30px 80px 20px 40px !important;
    }
    .Header-background {
        padding: 30px 40px 20px 80px !important;
    }
    html[lang="he"] .Nav-item.Nav-item-JoinUs {
        padding-left: 20px;
    }
    html[lang="es"] .navbar-nav {
        width: 500px;
    }
    .navbar-nav {
        width: 660px;
    }
}

@media only screen and (min-width: 993px) and (max-width: 1026px) {
    html[dir='rtl'] .Header-background {
        padding: 30px 80px 20px 40px !important;
    }
    .Header-background {
        padding: 30px 40px 20px 80px !important;
    }
    html[lang="es"] .navbar-nav {
        width: 486px;
    }
    html[lang='he'] .navbar-nav {
        width: 640px;
    }
    .navbar-nav {
        width: 584px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .Header-background {
        padding: 25px 0 0 0 !important;
    }
    .navbar-header {
        padding: 0 10px 25px 25px !important;
    }
    html[dir="rtl"] .navbar-header {
        padding: 0 25px 25px 10px !important;
    }
    .navbar-collapse {
        padding: 0 25px 10px 25px !important;
    }
    html[lang="es"] .navbar-nav {
        width: 430px !important;
    }
    html[lang='zh'] .navbar-nav {
        width: 340px !important;
    }
    html[dir="rtl"] .navbar-nav {
        width: 400px !important;
    }
    .navbar-nav {
        width: 415px !important;
    }
}


@media only screen and (min-width: 768px) {
    html[dir='rtl'] .navbar-header {
        float: right !important;
    }

    html[dir='rtl'] .navbar-nav {
        float: left !important;
        padding-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    html[dir='rtl'] .Header-logo {
        float: right !important;
    }

    html[dir='rtl'] .Header-hamburger {
        float: left !important;
    }
}

.Header-logo img {
    height: 55px;
}

.Header-hamburger {
    padding: 9px 10px;
    margin: 8px 0 0 8px;
    background-color: transparent;
    background-image: none;
    border: 2px solid #ffffff !important;
    border-radius: 4px;
    transition: all 0.5s ease;
}

.Header-hamburger .icon-bar,
.Header-hamburger:hover .icon-bar,
.Header-hamburger:focus .icon-bar,
.Header-hamburger:active .icon-bar {
    background-color: #ffffff !important;
}

.Header-hamburger:hover {
    background-color: transparent !important;
    box-shadow: 2px 2px 4px #a88838;
}

.Header-hamburger:focus {
    background-color: transparent !important;
}

.Header-collapse {
    padding: 0;
    overflow-x: visible;
    border-top: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.Header-collapse.in,
.Header-collapse.collapsing {
    overflow: hidden;
}

.Header-collapse.in,
.Header-collapse.collapsing {
    /*background: rgba(0, 0, 0, 0.4);*/
    background: #F2B816;
}

.Header-collapse.in li.Nav-item,
.Header-collapse.collapsing li.Nav-item {
    display: block;
    width: 100%;
    float: right;
    text-align: right;
}

.Header-collapse.in li.Nav-item-JoinUs,
.Header-collapse.collapsing li.Nav-item-JoinUs {
    width: auto;
    text-align: center;
    padding-top: 10px !important;
}

.Nav ul {
    list-style: none;
    float: right;
}

.Nav-item {
    display: inline;
    padding: 0 0 0 30px;
    font-weight: 500;
}

.Nav-item:first-of-type {
    padding-left: 20px !important;
}

html[dir='rtl'] .Nav-item:first-of-type {
    padding-left: 0 !important;
}

#JoinUsLink {
  padding: 12px 30px !important;
}

button[data-ddp-test="signInButton"], button[ data-ddp-test="signOutButton"] {
  margin-top: 0;
}

.Nav-itemLink {
    cursor: pointer;
    color: #ffffff !important;
    font-size: 1rem;
    line-height: 3.2rem !important;
    text-shadow: none;
    padding: 0 !important;
    transition: all 0.3s ease-in-out;
}

.Nav-itemLink--active {
    font-weight: 700;
    color: #a88838 !important;
}

.Nav-itemLink:hover {
    color: #a88838 !important;
}

.NoBackground {
    position: absolute !important;
    background-color: transparent !important;
}

.PageHeader-activity-subtitle {
    font-size: 0.95rem;
    font-weight: 300;
    line-height: 1.6em;
    color: mat.get-color-from-palette($app-theme, 100);
    margin: 5px 0 5px 0;
}

.Subtitle.Color--green {
  font-size: 1rem;
  font-weight: 700;
  padding-top: 20px;
  text-transform: uppercase;
}

.LastUpdatedText {
    text-align: right;
    font-weight: 300;
    padding: 5px;
    color: mat.get-color-from-palette($app-theme, 800);
}

// Start of footer section
.Footer {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 130px;
    padding: 20px 50px 20px 50px;
    z-index: 2;
    background-color: #f9f3e8;
    margin: 0 0 0 0;
    clear: both;
}

.Footer-link .SimpleButton {
  padding-left: 0 !important;
  line-height: 1.5rem !important;
  font-size: 0.9rem !important;
}

.Footer-left img {
  height: 50px;
}

.Footer-left {
  padding-left: 20px;
  padding-top: 5px;
  height: 90px;
}

html[dir='rtl'] .Footer-left {
    float: right;
}

p.Footer-contact {
  font-size: 0.9rem !important;
  color: #727272 !important;
  font-weight: 500 !important;
  margin-top: 10px;
  margin-bottom: 0;
}

.Footer-links {
  height: 90px;
  padding: 31px 0 0;
}

html[dir='rtl'] .Footer-links {
    float: right !important;
}

.Footer-text {
  font-size: 0.9rem !important;
  color: #727272;
  margin-top: 0;
  margin-bottom: 0;
}

.Footer-link, .Footer-link .SimpleButton {
  font-weight: 500;
  color: #727272;
  display: inline;
  cursor: pointer;
  text-align: right;
  transition: color 0.2s linear;
  padding-left: 30px;
  align-items: unset;
  background-attachment: unset;
  background-clip: unset;
  border: none;
  border-image-width: 0;
  white-space: nowrap;
}

html[dir='rtl'] .Footer-link {
    text-align: right;
}

.Footer-link:hover, .Footer-link .SimpleButton:hover {
  color: #82B547 !important;
}

button.Footer-link {
  background: transparent;
  border: none;
  padding: 0;
}

.Footer-arrow {
  font-size: 0.5rem;
  margin: 0 5px 5px 30px;
}

@media only screen and (max-width: 767px) {
  .Footer {
    padding: 20px 25px 20px 25px;
    min-height: 280px;
  }
  .Footer-left {
    padding-top: 6px;
    padding-left: 0;
    height: unset;
  }
  .Footer-links {
    padding-top: 0;
    height: unset;
  }
  .Footer-text {
    margin-top: 5px;
  }
  .Footer-text,
  .Footer-link,
  .Footer-link .SimpleButton{
    display: block;
    float: left !important;
    text-align: left;
    padding-left: 0;
    width: 100%;
  }
  .Footer-text {
    margin-top: 20px;
  }
  .Footer-arrow {
    margin-left: 0;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .Footer {
    padding: 20px 25px 20px 25px;
  }
  .Footer-left {
    padding-left: 0;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .Footer-links {
    padding-top: 20px;
  }
}
//End of footer section

//   Page header
.MainContainer {
    min-height: 100%;
    background-color: #f9f3e8;
    overflow: hidden;
    box-shadow: 1px 1px 5px #9E9E9E;
}

.PageHeader {
    height: auto;
    width: 100%;
    z-index: 1000;
}

.PageHeader-background {
    position: relative;
    background: #F2B816 none;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 100;
}

html[dir='rtl'] .PageLayout.row .col-lg-8.col-md-10.col-sm-12.col-xs-12 {
  float: right;
}

.PageLayout {
    width: 100%;
    margin: 0 auto;
}

.PageLayout-dashboard {
    max-width: 1200px !important;
}

.WIRB {
    font-size: 0.8rem !important;
}

.PageHeader-title {
    margin-left: -15px;
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0 0 10px 0;
    display: inline-block;
    font-weight: 500;
    font-size: 1.5rem;
}

.PageHeader-subTitle {
    white-space: normal;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.6em;
    color: mat.get-color-from-palette($app-theme, 600);
}

.PageHeader-title small {
    color: mat.get-color-from-palette($app-theme, 100);
    font-style: italic;
    font-weight: 300;
    font-size: 55%;
    display: block;
    line-height: 1.5;
}

.PageHeader-image .PageHeader-imageSpan .PageHeader-imageInner {
    padding-top: 42%;
    display: block;
    height: 0;
}

.PageHeader-box {
    position: relative;
    bottom: 150px;
    width: 60%;
    margin: 0 auto !important;
    border-radius: 2px;
    background-color: mat.get-color-from-palette($app-theme, 100);
    text-align: left;
    z-index: 1;
}

.PageHeader-boxContent {
    color: mat.get-color-from-palette($app-theme, 1000);
    padding: 40px;
    font-size: 1.5rem;
    line-height: 1.25;
    font-weight: 300;
}

.PageHeader-boxFooter {
    border-top: 1px solid rgba(160, 160, 160, 0.2);
    padding: 20px 40px;
    font-weight: 300;
    font-size: 0.95rem;
}

.Message {
    text-align: center;
    display: block;
}

.Message-title {
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 1.25;
    margin-bottom: 18px;
}

.Message-partners {
    height: auto;
}

.Message-quote {
    text-align: right;
    color: mat.get-color-from-palette($app-theme, 1000);
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 1.25;
    width: auto;
}

.WirbOuter {
    margin: 0 0 20px !important;
}

.Position--relative {
    position: relative;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.InputRadio-label {
    color: mat.get-color-from-palette($app-theme, 800);
    font-size: 1rem;
    font-weight: 300;
}

@media only screen and (max-width: 1025px) {
  .Header-nav {
    margin: 0;
  }

  .Header-logo {
    margin: 0;
  }
}

@media only screen and (max-width: 750px) {
    .Message-quote {
        margin: 0 30px;
    }
}

@media only screen and (max-width: 450px) {

    .PageContent-ol li,
    .PageContent-ul li {
        padding: 0 0 0 15px;
    }

    .PageHeader-background {
        padding-left: 40px;
    }

    .PageHeader-titleAboutUs {
        margin: 180px 0 30px 0 !important;
    }

    .PageHeader-title {
        padding: 20px 20px 0 20px;
    }

    .PageContent {
        background-color: #FFFFFF;
    }

    .PageHeader-box {
        bottom: 0;
        width: 100%;
        margin: 0 auto !important;
    }

    .Message-title {
        font-size: 2rem;
    }

    .Message-quoteExtraMargin {
        padding: 20px 40px;
    }

    .Message-quote blockquote {
        padding: 20px;
    }

    .Message-quote {
        padding: 20px;
    }

    .Message-quote blockquote:before {
        top: -6px;
        left: 0;
    }

    .Message-quote blockquote:after {
        bottom: -5px;
        right: -10px;
    }

    .PageHeader-title {
        padding: 20px 20px 0 20px;
    }

    .PageHeader-activity-subtitle {
        padding: 0 20px 10px 20px;
    }

    .Modal-title {
        margin: 0 !important;
        font-size: 2rem !important;
        line-height: 2rem !important;
    }

    .Modal-text {
        margin: 0 !important;
    }

    .modal-message {
        .warning-message-text {
            margin: 0 !important;
        }
    }

    .mat-dialog-actions {
        padding: 12px 0 0 0 !important;

        .ButtonFilled {
            padding: 9px 10px !important;
        }
    }

    .PageHeader-subTitle {
        font-size: 1rem;
        line-height: 1.3rem;
    }
}

// Page
.PageContent{
    background: #FFFFFF;
}

.Wrapper {
    background: #FFFFFF;
}

.HeaderCutOut {
    background: #FFFFFF;
    margin-left: 16%;
    margin-right: 16%;
    height: 100%;
}

.PageContent-section {
    padding: 1rem 0;
}

.Dashboard-info-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 0 20px 0;
    padding: 35px 35px 30px 35px;
    background: #f9f3e8;
    border: 2px solid mat.get-color-from-palette($app-theme, 600);
    position: relative;

    .close-button {
        display: flex;
        justify-content: center;
        position: absolute;
        right: 0;
        top: 0;
    }
}

ul {
    li.PageContent-text-list {
        padding: 0 !important;
        margin: 10px 0 !important;
    }
}

.PageContent-text,
.PageContent-box-text {
    font-weight: 300;
    font-size: 1.1rem;
    text-align: left;
}

.PageContent-image {
    width: 100%;
    height: auto;
}

.Link {
    color: mat.get-color-from-palette($app-theme, 600) !important;
    cursor: pointer;
    font-weight: 500;
    font-size: 1rem;
    padding: 20px 0 0 0 !important;
    margin-bottom: 15px;
    transition: all 0.3s ease-in-out;
    text-align: center;
}

.Link:hover,
.Link:focus {
    color: mat.get-color-from-palette($app-theme, 700) !important;
}

.Link--neutral {
    color: #9E9E9E;
}

.Link--neutral:hover {
    color: #82B547;
}

.Link--green {
    color: #82B547 !important;
}

.Link--green:hover {
  color: mat.get-color-from-palette($app-theme, 600) !important;
}

.PageContent-title {
    font-size: 1.5rem;
    color: rgb(51, 51, 51);
    font-weight: 500;
    line-height: 1.1;
    margin: 20px 0 15px 0;
}

.Field-padding {
    padding-bottom: 25px;
}

.InputRadio-extraMargin {
    margin: 40px 0;
}

.HorizontalLine {
    margin: 20px 0 20px 0;
    border-top: 1px solid #eee !important;
}

.topMarginMedium {
    margin: 15px 0 0 0;
}

.Italic {
    font-weight: 300 !important;
    font-style: italic;
}

.Color--neutral {
    color: mat.get-color-from-palette($app-theme, 800) !important;
}

.Normal {
    font-weight: 300 !important;
}

.ConsentButtons {
    display: flex;
    justify-content: space-between;
}

.NextButton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

// welcome
.Intro {
    position: absolute;
    top: 0;
    margin: 0;
    max-width: 100%;
    display: inline-block;
}

.Intro-image .Intro-imageSpan {
    display: inline-block;
    width: 100%;
    font-size: 0;
    line-height: 0;
    vertical-align: middle;
    background-size: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url(/assets/images/home-image-lg.png);
    position: relative;
}

@media only screen and (min-width: 1280px) {
    .Intro-image .Intro-imageSpan {
        background-image: url(/assets/images/home-image-lg.png);
    }

    .Intro-image .Intro-imageSpan .Intro-imageInner {
        padding-top: 51% !important;
    }
}

@media only screen and (max-width: 1280px) {
    .Intro-image .Intro-imageSpan .Intro-imageInner {
        padding-top: 55% !important;
    }
}

@media only screen and (max-width: 800px) {
    .Intro-image .Intro-imageSpan {
        background-image: url(/assets/images/home-image-md.png);
    }

    .Intro-image .Intro-imageSpan .Intro-imageInner {
        padding-top: 68% !important;
    }
}

@media only screen and (max-width: 600px) {
    .Intro-image .Intro-imageSpan {
        background-image: url(/assets/images/home-image-sm.png);
    }

    .Intro-image .Intro-imageSpan .Intro-imageInner {
        padding-top: 175% !important;
    }
}

.Intro-image .Intro-imageSpan .Intro-imageInner {
    padding-top: 50%;
    display: block;
    height: 0;
}

.Intro-arrow {
    position: absolute;
    bottom: 20px;
    opacity: 1;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.Arrow {
    cursor: pointer;
}

.Intro-footer {
    text-align: center;
    margin: 0 0 -50px 0;
}

.Intro-footerLogos {
    margin: 10px 20px;
    height: 30px;
}

@media only screen and (max-width: 450px) {
    .Intro-footerLogos {
        margin: 10px 10px;
    }
}

.Message--intro {
    margin-top: 150px;
}

@media only screen and (max-width: 1280px) {
    .Message--intro {
        margin-top: 100px;
    }
}

@media only screen and (max-width: 1024px) {
    .Message--intro {
        margin-top: 100px;
    }
}

@media only screen and (max-width: 450px) {
    .Message--intro {
        margin-top: 100px;
    }
}

.Message--intro .Message-title,
.Message--intro .Message-text {
    color: mat.get-color-from-palette($app-theme, 1000);
}

.Separator {
    width: 1px;
    background: mat.get-color-from-palette($app-theme, 700);
    margin: 50px auto;
    position: relative;
}

.Separator--small {
    height: 125px;
}

.Separator--large {
    height: 200px;
}

.Separator:before,
.Separator:after {
    content: ' ';
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    position: absolute;
    left: -3px;
    background: mat.get-color-from-palette($app-theme, 700);
}

.Separator:before {
    top: -3px;
}

.Separator:after {
    bottom: -3px;
}

.DNAStrand {
    width: 100%;
    margin: 35px 0;
}

.Message-text {
    font-size: 1rem;
    font-weight: 300;
    color: mat.get-color-from-palette($app-theme, 1100);
    line-height: 1.6;
    margin-bottom: 18px;
}

.Message-turtle {
    margin: 220px 0 35px 0;
}

.Message-step {
    text-align: center;
    margin: 25px 0 35px 0;
    padding: 0 30px;
}

.Message-stepImage {
    max-height: 100%;
}

.Message-stepTitle {
    font-size: 1.5rem;
    display: block;
    font-weight: 400;
    line-height: 1.33;
    text-align: left;
    padding: 40px 0 0 30px;
}

.Message-stepSubtitle {
    font-size: 0.95rem;
    display: block;
    font-weight: 300;
    line-height: 1.33;
    text-align: left;
    padding: 0 0 20px 30px;
}

.Message-stepText {
    font-size: 0.95rem;
    font-weight: 300;
    line-height: 1.6;
    text-align: left;
    padding: 0 0 0 30px;
    color: mat.get-color-from-palette($app-theme, 800);
}

.Message-quote blockquote {
    padding: 0;
    margin: 0;
    border: none;
    position: relative;
    font-size: 1.5rem;
    line-height: 1.25;
}

.Message-quote blockquote:before {
    content: '\201C';
    top: -10px;
    left: -15px;
}

.Message-quote blockquote:after {
    content: '\201D';
    bottom: -25px;
    right: -15px;
}

.Message-quote blockquote:before,
.Message-quote blockquote:after {
    font-size: 60px;
    line-height: 1;
    color: mat.get-color-from-palette($app-theme, 600);
    position: absolute;
}

.Semibold {
    font-weight: 400 !important;
}

.Color--orange {
    color: mat.get-color-from-palette($app-theme, 600);
}

.Semibold {
    font-weight: 400 !important;
}

.PageContent-ol,
.PageContent-ul {
    margin: 20px !important;
    padding: 0 !important;
    text-align: left !important;
    font-weight: 300 !important;
}

.PageContent-ol {
    list-style-type: decimal;
    list-style-position: outside;
}

.PageContent-ol li,
.PageContent-ul li {
    padding: 0 0 0 25px !important;
    font-size: 1.1rem !important;
    font-weight: 300 !important;
    margin: 35px 0 20px 0 !important;
    position: relative;
}

.PageContent-ol li::before,
.PageContent-ul li::before {
    position: absolute;
    display: inline-block;
    content: "";
    height: 100%;
}

.Sub-item {
    margin: 35px 0 20px 0 !important;
    padding: 0 0 0 25px !important;
}

.NoListStyle {
    list-style-type: none;
}

.Login-landing-header {
    height: 78px;
    background-color: mat.get-color-from-palette($app-theme, 600);
}

.Modal-title {
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: rgba(0, 0, 0, 0.5);
    margin: 10px 0 12px 0;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    position: relative;

    .mat-icon-button {
        top: -20px;
        right: -15px;
    }
}

.Modal-text {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.95rem;
    line-height: 1.6rem;
    font-weight: 300;
    margin-bottom: 15px;
}

.Modal-progress {
    margin: 5px 0;
}

.ErrorMessage {
    margin-top: 0;
    padding-top: 0;
    font-size: 0.7rem;
    color: #F44336;
    font-weight: 400;
}

.Question--text, ddp-activity-text-answer, .ddp-option-details-field {
  .mat-form-field-wrapper, .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-underline {
    bottom: 0;
  }

  .mat-form-field-infix {
    border-top: none !important;
  }
}

.mat-form-field, .Question--text, ddp-activity-text-answer, .ddp-option-details-field {
  input.mat-input-element {
    color: rgb(113, 113, 116);
    line-height: 2.5rem !important;
    height: 40px !important;
    width: 100%;
    font-size: 0.9rem !important;
  }
}

.Question--text {
    .ErrorMessage {
        margin-top: 0;
        padding-top: 5px;
        border-top: solid 2px #F44336;
    }
}

.JoinDialogFiled {
    width: 100%;
}

.JoinDialogNameField {
    width: 50%;
    margin: 0 30px 0 0;
}

.JoinDialogLastnameField {
    width: 50%;
}

.JoinDialogGroupFields {
    display: flex;
}

.JoinField--margin {
    margin-bottom: 5px;
}

@media only screen and (max-width: 650px) {
    .JoinDialogGroupFields {
        flex-direction: column;
    }

    .JoinDialogNameField {
        width: 100%;
        margin: 0;
    }

    .JoinDialogLastnameField {
        width: 100%;
    }
}

@media only screen and (max-width: 599px) {
    .HeaderInfo {
        margin: 0
    }
}

.ResendEmailField {
    width: 100%;
    margin-bottom: 5px;
}

html[dir='rtl'] .WizardStepsContainer {
    display: flex;
}

.WizardSteps {
     position: relative;
     margin: 24px 0 24px 0;
     padding: 0 0 0 0;
     text-align: center;
     cursor: pointer;
     width: calc(100% / 7);
 }

.WizardSteps-background {
    position: relative;
    margin-top: 12px;
    height: 4px;
    width: 100%;
    background-color: #efefef;
}

.WizardSteps.completed .WizardSteps-background,
.WizardSteps.completed .WizardSteps-title {
    background-color: #dae9c8;
    background-image: none;
}

.WizardSteps.active.completed .WizardSteps-background,
.WizardSteps.active.completed .WizardSteps-title {
    background-color: #82B547;
    background-image: none;
}

.WizardSteps-title {
    position: absolute;
    top: -14px;
    left: 50%;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: #efefef;
    font-size: 16px;
    line-height: 32px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
    margin-left: -17px;
    margin-bottom: 10px;
}

.WizardSteps-img {
    width: 0;
    height: 0;
}

.Loader {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 12px solid;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin-top: -50px;
    margin-left: -30px;
    animation: spin 2s linear infinite;
}

.Loader-height {
    height: 50vh;
}

.PageContent-box {
    width: 100%;
    padding: 3rem;
    background: #f9f3e8;
    border: 2px solid mat.get-color-from-palette($app-theme, 600);
    box-shadow: none;
    font-size: 1rem;
}

.PageContent-box-text {
    color: mat.get-color-from-palette($app-theme, 1000);
}

.PageContent-strong-text {
    margin: 0 0 0 40px;
}

@media only screen and (max-width: 450px) {
    .PageContent-box {
        padding: 2.2rem 2rem 2rem 2rem;
    }
}

.PageContent-contact-item {
    font-weight: 300 !important;
    font-size: 1.1rem !important;
    line-height: 1.6rem;
    color: mat.get-color-from-palette($app-theme, 800) !important;
    margin: 0 !important;
}

.PageContent-list-item {
    list-style-type: circle !important;
}

.PageContent-info {
    margin: 0 20px;
}

.PageContent-closing-text {
    color: mat.get-color-from-palette($app-theme, 800);
    font-size: 1rem;
    font-weight: 300;
    margin: 0 20px;
}

.PageContent-closing-question {
    margin: 35px 20px -25px 20px !important;
}

.PageContent-infobox-section {
    padding: 2rem 0 0 0;
}

.PageContent-infobox {
    padding: 1rem;
    background: #f9f3e8;
    border: 2px solid mat.get-color-from-palette($app-theme, 600);
    box-shadow: none;
    font-size: 1rem;
    margin: 0 20px;
}

.PageContent-subtitle {
    color: rgb(51, 51, 51);
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 2rem 0 1rem 0;
}

.PageContent-subtitle-dashboard {
  margin: 20px 0 15px 0;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.1;
}

.PageContent-text-dashboard {
    font-size: 1rem !important;
}

html[dir='rtl'] .PageContent-text-dashboard {
    text-align: right;
}

.PageContent-text br {
    line-height: 2.5rem;
}

@-moz-document url-prefix() {
    .PageContent-text br {
        content: " ";
        display: block;
        margin: 0.4rem;
    }
}

.Announcements-section {
    align-self: stretch;
}

select {
    width: 100%;
    height: 40px;
    transition: all .3s;
    border: 1px solid #cecece !important;
    font-size: 0.9rem;
    padding: 0 10px 0 10px;
    margin: 5px 0 30px 0;
    background-color: rgb(248, 248, 248);
    color: #717174;
}

select option {
    color: black;
}

select:-webkit-autofill {
    background-color: transparent !important;
}

.warning-message {
    position: fixed;
    height: $warning-height;
    top: 0;
    left: 0;
    width: 100%;
    background-color: mat.get-color-from-palette($app-theme, 2000);
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px;
}

.warning-message {
    .warning-message-text {
        color: mat.get-color-from-palette($app-theme, 1700);
        font-size: 1.1rem;
        line-height: 1.1rem;
        font-weight: 300;
        width: 100%;
        text-align: center;
    }
}

.modal-message {
    .warning-message-text {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.95rem;
        line-height: 1.6rem;
        font-weight: 300;
        margin-bottom: 15px;
    }
}

.warning-message-top {
    top: $warning-height !important;
}

.NegMargin {
    margin-top: -5px !important;
    padding-top: 0 !important;
}

.ddp-li {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
}

.ddp-list {
    margin: 20px;
    padding: 0;
}

.ddp-list li {
    font-size: 1.1rem;
    font-weight: 300;
    margin: 0 0 0 0;
    color: mat.get-color-from-palette($app-theme, 1700);
    padding: 0 0 0 0;
}

.ddp-list-inside {
    margin: 0;
    padding: 0;
    list-style-position: inside;
}

.ddp-list-inside li {
    padding: 0;
    font-size: 1.2rem;
    font-weight: 400;
}

.list {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400 !important;
}

.list--spaced li {
  padding-top: 10px;
}

.list--sublist li {
  padding-top: 0;
}

.mat-form-field-underline {
  visibility: hidden;
}

mat-form-field.mat-form-field-type-mat-input {

  margin: 0 0 20px 0 !important;

  input {
    border: 1px solid #cecece !important;
    height: 35px !important;
      padding-left: 0.6em;
  }
}

mat-form-field.mat-form-field-type-mat-input.mat-focused input {
  border-color: #82B547 !important;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 5px #82B547 !important;
}

.ddp-agreement-text {
    white-space: normal;
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
    color: mat.get-color-from-palette($app-theme, 800);
}

.ddp-question {
    margin: 35px 0 20px 0;
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.6rem;
    color: #333 !important;
    padding: 0;

    .ddp-required-question-prompt {
        color: #333;
        font-size: 0.9rem;
        margin-bottom: 0 !important;
    }

    li {
        color: mat.get-color-from-palette($app-theme, 800);
    }
}

.ddp-required-question-prompt {
    font-weight: 300;
    color: #333 !important;
}

.ddp-list {
    p {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400;
        color: mat.get-color-from-palette($app-theme, 1700);
    }

  p.Input-label {
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  p.Medium.UnorderedItem {
    color: #717174;
  }
}

p.ddp-question-prompt{
  margin-bottom: 0 !important;
}

html[dir='rtl'] .BulletItem {
    text-align: right;
}

.BulletItem {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-size: 1rem;
  font-weight: 300;
  margin: 0 0 10px 0;
  text-align: left;
  padding: 0 0 0 0;
  color: #717174 !important;
}

p.Input-label {
  font-size: 0.9rem;
  font-weight: 400;
  margin-bottom: 0;
}

.ddp-dropdown {
    padding: 10px;
    margin: 0 0 5px 0;
    border: 1px solid mat.get-color-from-palette($app-theme, 1800);
    border-radius: 2px;
}

.ddp-block-body {
    margin: 0 0 0 0;
    color: #333333;

    .PageContent-text {
        margin: 14px 0 0 0;
        color: #333333;
    }
}

.ddp-block-title-bold {
    color: mat.get-color-from-palette($app-theme, 1000);
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 2rem 0 1rem 0;
}

.ddp-single-question {
    margin: 0 0 0 -15px;
}

.PageContent-contact {
    list-style-type: disc;
    margin: 10px 0 0 0;
}

.ddp-block-title-color {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 300;
    margin: 2rem 0 1rem 0;
    color: mat.get-color-from-palette($app-theme, 800);
}

.ddp-li-bullet {
    color: mat.get-color-from-palette($app-theme, 800) !important;
}

.ddp-date-field-margin {
    margin: 0 15px 0 0;
}

.ddp-content {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
}

.ddp-agreement {
    margin: 0 0 0 0;
}

.ddp-group-block {
    margin: 0;
}

.ddp-group-block-list {
    padding: 0 !important;
    margin: 20px 0 0 0 !important;
}

.ddp-group-margin {
    margin: 20px;
}

.ddp-textarea {
    height: 1.5rem !important;
}

html[dir='rtl'] .ddp-answer-container {
    flex-direction: row-reverse;
}

.ddp-answer-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

ddp-activity-composite-answer .ddp-answer-container {
  margin-left: 5px;
}

@media (min-width: 1200px) {
  .ddp-answer-container {
    ddp-activity-answer {
      margin: 7px;
      width: 47%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ddp-answer-container {
    ddp-activity-answer {
      margin: 7px;
      width: 47%;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .ddp-answer-container {
    ddp-activity-answer {
      width: 100%;
    }
  }
}

@media (max-width: 767px) { //col-xs-12
  .ddp-answer-container {
    ddp-activity-answer {
      width: 100%;
    }
  }
}

.ddp-close-button {
    margin-left: auto;
    cursor: pointer;
    color: mat.get-color-from-palette($app-theme, 300);
    font-size: 1rem;
    font-weight: 900;
}

.ddp-user-menu-button {
    outline: none;
    font-size: 1rem;
    font-weight: 300;
    color: mat.get-color-from-palette($app-theme, 1000);
}

.ddp-user-menu-button:active,
.ddp-user-menu-button:focus,
.ddp-user-menu-button:hover {
    color: mat.get-color-from-palette($app-theme, 1000);
}

.ddp-user {
    color: mat.get-color-from-palette($app-theme, 100);
}



.ddp-institutions-form,
.ddp-activity-question {
    display: inline-block;
    vertical-align: top;
    width: 100%;
}

.ddp-hide-block {
    margin: 0 !important;
    padding: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
}

.ddp-hide-subtitle {
    opacity: 0;
    transition: all 0.3s ease;
}

.ddp-menu-text {
    padding: 0 16px !important;
}

@media (max-width: 512px) {
    #dashboard .ddp-dashboard-date {
        display: none !important;
    }
}

.dashboard-activity-button {
    font-size: 0.9rem;
    font-weight: 400;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-decoration: none;
    text-align: left;
}

.dashboard-status-container__img {
    height: 0 !important;
    width: 0 !important;
}

.ddp-picklist-option-list-item {
    height: auto !important;
    margin-bottom: 10px;
}

.ddp-picklist-group-title {
    font-size: 1.25rem;
    font-weight: 500;
}

/*html[dir='rtl'] .example-radio-group {
    flex-direction: row-reverse;
}*/

.example-radio-group {
    display: flex;
    flex-direction: row;
}

.ddp-login-button {
    @extend .mat-button;
}

.subhead:after {
    content: "";
    display: table;
    clear: both;
}

@media only screen and (max-width: 1100px) {
    .ddp-picklist-group-container {
        grid-template-columns: repeat(2, 50%) !important;
    }
}

@media only screen and (max-width: 600px) {
    .ddp-picklist-group-container {
        grid-template-columns: repeat(1, 100%) !important;
    }
}

.mat-snack-bar-container {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .24), 0 0 2px rgba(0, 0, 0, .12);
    margin: 4px;
    background-color: mat.get-color-from-palette($app-theme, 100);
    padding: 0 0 0 0 !important;
}

.mat-checkbox-label {
    color: #717174;
    font-weight: 400;
}

.ddp-picklist-option-list-item .mat-checkbox-layout .mat-checkbox-label {
  font-size: 0.9rem;
  line-height: 1.4rem;
}

.mat-select-placeholder {
    color: mat.get-color-from-palette($app-theme, 1700);
}

select.mat-input-element {
    padding-top: 0!important;
    padding-left: 0.6em;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
    right: 3%!important;
    top: 32%!important;
}


.mat-checkbox-frame {
  border-color: #CECECE;
  border-width: 1px !important;
  border-radius: 1px !important;
}

.mat-radio-label {
    font-weight: 300;
}

.mat-input-flex {
    font-weight: 300;
}

.mat-dialog-container {
    background-color: mat.get-color-from-palette($app-theme, 1300);
}

.mat-form-field-invalid .mat-form-field-label {
    color: mat.get-color-from-palette($app-theme, 1200);
}

label.mat-form-field-label {
  font-size: 0.9rem;
  font-weight: 400;
}

.mat-error {
    color: mat.get-color-from-palette($app-theme, 1200);
}

.mat-form-field-invalid .mat-form-field-ripple {
    background-color: mat.get-color-from-palette($app-theme, 1200);
}

.mat-form-field-label-wrapper {
    font-weight: 300;
    font-size: 1rem;
}

.ddp-option-details-field.mat-form-field, .ddp-picklist-option-list-item .mat-form-field {
  margin-bottom: 0 !important;
  margin-top: 20px !important;
}

.ddp-option-details-field.mat-form-field .mat-form-field-wrapper, .ddp-picklist-option-list-item .mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}

.ddp-option-details-field .mat-form-field-label, .ddp-picklist-option-list-item .mat-form-field-label {
  top: 0 !important;
  color: #717174 !important;
}

.ddp-option-details-field.mat-form-field-should-float .mat-form-field-label, .ddp-picklist-option-list-item .mat-form-field-should-float .mat-form-field-label {
  transform: unset !important;
  color: #717174 !important;
}

.mat-input-placeholder {
    font-weight: 300;
    font-size: 1rem;
}

.mat-error {
    font-weight: 300;
    font-size: 0.75rem;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background
{
  background-color: #82B547;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #82B547;
  border-width: 2px !important;
}

.mat-radio-outer-circle {
    border-color: #CECECE;
    border-width: 1px !important;
}

.mat-list-item-content {
    padding: 0 4px !important;
}

.mat-checkbox-layout .mat-checkbox-label {
    font-size: 1rem;
}

.mat-radio-label-content {
    font-size: 0.9rem !important;
    font-weight: 400;
    margin-bottom: 0;
    color: #717174;
    line-height: 1.4rem !important;
}

.mat-form-field-infix {
    font-size: 1rem;
}

.mat-list .mat-list-item .mat-list-item-content {
    display: block !important;
}

.mat-input-element {
  caret-color: unset !important;
  height: 2.0rem !important;
  font-size: 1rem;
}

input.mat-input-element {
    height: 1.2rem !important;
    font-size: 1rem;
}

.mat-form-field .mat-form-field-subscript-wrapper {
    overflow: visible !important;
}

.mat-input-element {
    line-height: 1.1rem;
    font-size: 1rem;
}

.mat-radio-outer-circle,
.mat-radio-inner-circle {
    height: 16px !important;
    width: 16px !important;
}

.mat-radio-container {
    margin-top: 3px !important;
    height: 16px !important;
    width: 16px !important;
}

.mat-radio-checked .mat-radio-inner-circle {
    transform: scale(.4) !important;
}

.mat-menu-item {
    padding: 0 !important;
}

@media only screen and (min-width: 768px) {

    .mat-row:first-child,
    .mat-header-row:first-child {
        flex: 0 0 40% !important;
    }

    .mat-row:last-child,
    .mat-header-row:last-child {
        flex: 0 0 15% !important;
    }
}

.mat-checkbox-inner-container:hover .mat-checkbox-persistent-ripple {
    opacity: 0 !important;
}

.mat-checkbox-inner-container {
    width: 16px !important;
    height: 16px !important;
}

.mat-input-element {
    font-weight: 400 !important;
}

.mat-select {
    font-weight: 100 !important;
}

p.ddp-helper {
    display: none !important;
}

.ddp-counter-color {
    color: mat.get-color-from-palette($app-theme, 600) !important;
}

.mat-option-text {
    line-height: 16px !important;
    white-space: normal !important;
}

@media only screen and (max-width: 480px) {
    .mat-option-text {
        font-size: 14px !important;
    }

    .mat-form-field-placeholder {
        font-size: 12px !important;
        white-space: normal !important;
    }
}

.mat-progress-bar-buffer {
    background-color: lightgray !important;
}

//Home page
.Landing-content {
    padding-top: 18%;
    margin-left: 50px;
    text-align: center;
}

@media only screen and (min-width: 1300px) {
    .Landing-content {
        padding-top: 12%;
    }
}

@media only screen and (max-width: 767px) {
    .Landing-content {
        padding-top: 30%;
        margin-left: 0;
    }
}

.Landing-image {
  position: relative;
  background-size: cover !important;
  background: url(/assets/images/home-image-md.png) no-repeat center;
}

.Landing-image:after {
    content: " ";
    display: block;
    width: 100%;
    padding-top: 80%;
}

@media only screen and (min-width: 1300px) {
    .Landing-image {
      background-size: cover;
      background: url(/assets/images/home-image-lg.png) no-repeat center;
    }
    .Landing-image:after {
        content: " ";
        display: block;
        width: 100%;
        padding-top: 54%;
    }
}

@media only screen and (max-width: 767px) {
    .Landing-image {
      background-size: cover;
      background: url(/assets/images/home-image-sm.png) no-repeat center;
    }
    .Landing-image:after {
        content: " ";
        display: block;
        width: 100%;
        padding-top: 175%;
    }
}

.Message-content {
    margin: 150px auto;
}

.HomeSteps {
    margin-top: 5px;
}

.Message-icon {
    height: 100%;
    padding: 15px 25px 20px 25px;
}

@media screen and (max-width: 64.063em) {
    .Message-icon {
        padding: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .col-xs-8.col-sm-offset-2 {
        margin-left: 16.666%;
    }
}

.MedicalTopBar {
  margin-top: 4px;
}

.Container {
    background: #ffffff;
    overflow: hidden;
    margin: 0 50px 0 50px !important;
    /*footer height + padding*/
    padding: 30px 30px 80px 30px;
}

@media only screen and (max-width: 992px) {
    .Container {
        margin: 0 25px 0 25px !important;
    }
}

@media only screen and (max-width: 767px) {
    .Container {
        margin: 0 0 0 0 !important;
    }
}

.ContainerSurvey {
    background: #ffffff;
    top: 0;
    margin-bottom: 20px;
    /*footer height + padding*/
    padding: 30px !important;
    box-shadow: 1px 1px 5px #9E9E9E;
}

@media only screen and (max-width: 767px) {
    .ContainerSurvey {
        margin: 0 0 20px 0 !important;
    }
}

.ContainerSurvey-top {
    margin: 0 !important;
    background: #F2B816;
    width: 100%;
    height: 40px;
    position: relative;
}

.Icon--remove {
    display: inline-block;
    font-size: 0.95rem;
    color: #a8a8a8;
    float: right;
    cursor: pointer;
}

.List-item {
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 300;
}

.List-question {
    font-size: 1.1rem;
    line-height: 1.6rem;
    font-weight: 500;
}

.Box {
    margin: 20px 0;
    padding: 15px 15px 5px 15px;
    border-radius: 2px;
}

.Box-text {
    margin: 0;
    text-align: center;
}

.InputText,
.InputDropdown {
    width: 100%;
    height: 40px;
    font-size: 0.9rem;
    padding: 0 10px 0 10px;
    margin: 5px 0;
    border: 1px solid #cecece !important;
}

.InputText.Error {
    border: 2px solid #F44336 !important;
    box-shadow: none !important;
}

.Error-message {
    font-size: 0.7rem;
    color: #F44336;
}

.Input-label,
.Input-checkLabel {
    font-size: 0.9rem;
    font-weight: 400;
    margin-bottom: 0;
}

.Input-label.SubLabel {
  display: inline-block;
  max-width: 100%;
  color: #717174;
}

.Input-checkLabel {
    cursor: pointer;
}

.InputRadio {
    width: 100%;
    padding: 10px 0 0 0;
}

.InputTextarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
}

.Or {
    margin: -10px auto;
    background: #ffffff;
    width: 50px;
}

.HorizontalLine--or {
    border: none;
    border-top: 2px solid #e6e6e6;
    margin: 50px 0;
}

.CommentLink {
    font-size: 0.75rem;
    font-weight: 300;
    font-style: italic;
    color: #717174;
}

.CommentLink:hover {
    color: #82B547;
}

.ProgressBar .progress-bar-info {
    background-image: none !important;
    background-color: #82B547 !important;
}

.ProgressBar .progress {
    height: 8px !important;
    margin-top: 10px;
    border-radius: 4px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.Steps {
    margin: 0;
    text-align: right;
    color: #717174;
    font-weight: 300;
    font-style: italic;
}


/******GENERAL CLASSES*/

.NoMargin {
    margin: 0 !important;
}

.NoMarginTop {
    margin: 0 10px !important;
}

.NoMarginBottom {
    margin-bottom: 0 !important;
}

.NoTopMargin {
  margin-top: 0
}

.LeftNegMargin {
  margin-left: -15px !important;
}

.TopMargin10 {
  width: 100%;
  margin: 10px 0 0 0 !important;
}

.TopMargin20 {
  width: 100%;
  margin: 20px 0 0 0 !important;
}

.TopMargin30 {
  width: 100%;
  margin: 30px 0 0 0 !important;
}

.TopMargin40 {
  width: 100%;
  margin: 40px 0 0 0;
}

.BottomMargin10 {
  margin-bottom: 10px !important;
}

.Margin10 {
  margin-top: 10px;
}

.Margin20 {
  margin-top: 20px;
}

.Margin30 {
  margin-top: 30px;
}

.NoPadding {
  padding: 0 !important;
}

.TopPadding10 {
  padding-top: 10px !important;
}

.TopPadding20 {
  padding-top: 20px !important;
}

.NoTopPadding {
  padding-top: 0 !important;
}

.NoBottomPadding {
  padding-bottom: 0 !important;
}

.Float--left {
    float: left !important;
}

.Float--right {
    float: right !important;
}

.Aligned--left {
    text-align: left !important;
}

.Aligned--right {
    text-align: right !important;
}

.Aligned--center {
    text-align: center !important;
}

.UnorderedList,
.OrderedList {
    padding-left: 20px;
}

.UnorderedList li,
.OrderedList li {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.4rem;
    color: #717174;
}

.NoListStyle {
    list-style-type: none;
}

.FullWidth {
    width: 100%;
}

.Color--yellow {
    color: #F2B816 !important;
}

.Background--yellow {
    background-color: #F2B816;
}

.Background--darkYellow {
    background-color: #a88838 !important;
}

.Background--lightYellow {
    background-color: rgba(242, 184, 22, 0.3);
    /*background-color: #F3D06F;*/
}

.Color--green {
    color: #82B547 !important;
}

.Background--green {
    background-color: #82B547;
}

.Background--darkGreen {
    background-color: #6C9635 !important;
}

.Background--lightGreen {
    background-color: #AFDD73;
}

.Background--grey {
    background-color: #efefef;
}

.Color--white {
    color: #ffffff;
}

.Color--neutral {
    color: #8c8c8c !important;
}

.Color--black {
    color: black !important;
}

.Normal {
    font-weight: 400!important;
}

.Medium {
    font-weight: 500 !important;
}

p.Medium {
    font-weight: 500 !important;
}

.Italic {
    font-weight: 300 !important;
    font-style: italic;
}

.Position--absolute {
    position: absolute;
}

.Position--relative {
    position: relative;
}

.Hidden {
    visibility: hidden;
}

.Display--inline {
    display: inline-block;
}

.CenterDiv {
    text-align: center;
    display: block;
    width: 100%;
}

@media (min-width: 0px) and (max-width: 767px) {
    .RowSameHeight-xs {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .RowSameHeight-sm {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .RowSameHeight-md {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}

@media (min-width: 1200px) {
    .RowSameHeight-lg {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}

.AlertMessage .alert {
    border: none !important;
    border-radius: 0 !important;
    background-image: none !important;
    text-align: center;
    padding: 5px auto;
    font-weight: 500 !important;
    font-size: 1rem;
    width: 100%;
}

.AlertMessage .alert-danger {
    background: rgba(200, 0, 17, 0.15) !important;
    color: #C80011 !important;
}

.AlertMessage .alert-warning {
    background: rgba(255, 189, 82, 0.2) !important;
    color: #D38405;
}

.AlertMessage .alert-success {
    background: rgba(130, 181, 71, 0.15) !important;
    color: #82B547;
}

.tooltip.in {
    opacity: 1 !important;
}

.tooltip .tooltip-arrow {
    border-top-color: #efefef !important;
}

.tooltip .tooltip-inner {
    background-color: #efefef !important;
    color: #717174;
    font-size: 0.7rem;
    max-width: 250px !important;
}



.DisplayNone {
  display: none;
}

.mat-row {
  min-height: auto;
  padding: 5px 0;
}

.mat-cell,
.mat-header-cell,
.dashboard-mobile-label {
  font-size: 0.9rem;
}

.mat-column-summary {
  flex: 0 0 35%;
}

.mat-cell {
  display: flex;
  align-items: center;
  overflow: unset;
  min-height: auto;
}

.dashboard-mobile-label {
  display: none;
}

.dashboard-status-container {
  display: flex;
  align-items: center;
}

.dashboard-status-container__img {
  height: 36px;
  width: 36px;
}

.padding-5 {
  padding: 0 5px 0 0 !important;
}

@media(max-width: 650px) {
  .mat-cell {
    align-items: flex-start;
    padding: 0 0 12px 0;
  }

  .ddp-dashboard .mat-header-row {
    display: none;
  }

  .mat-column-status {
    align-items: center;
  }

  .mat-row {
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0 0 0;
  }

  .mat-row::after {
    display: none;
  }

  .dashboard-mobile-label {
    min-width: 75px;
    display: inline-block;
  }
}

//Make activity column wider than summary column
.mat-column-name {
  flex-basis: 25% !important;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;
}

.mat-column-summary {
  flex-basis: 0 !important;
  flex-grow: 1 !important;
  flex-shrink: 1 !important;
}

.PrivacyPolicyFull section {
  padding: 0;
}

html[dir='rtl'] .cookiesBanner {
    flex-direction: row-reverse;
}

.cookiesBanner {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  bottom: 0;
  min-height: 124px;
  width: 100%;
  padding: 20px 75px;
  background-color: rgba(13, 13, 11, 0.9);
  z-index: 10;

  &--text {
    color: #FFFFFF;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  &--link {
    min-width: fit-content !important;
    padding: 0;
    color: #FFFFFF;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
    text-decoration: underline !important;
    cursor: pointer;
    background-color: transparent;
    border: none;
    box-shadow: none;


    &:hover,
    &:active {
      color: #a88838;
    }
  }

  .policy {
    margin: 0 !important;
    padding: 0 !important;
  }

  button {
    min-width: 180px;
    padding: 10px;
    margin: 0 0 0 10px;
  }

  > a {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 992px) {
  .cookiesBanner {
    flex-direction: column;
    padding: 20px 45px;

    button, a {
      margin: 10px 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .cookiesBanner {
    padding: 15px;
  }
}

html[dir='rtl'] .cookiesModal.cookiesModal--header,
html[dir='rtl'] .privacyModal.privacyModal--header {
    flex-direction: row-reverse;
}

html[dir='rtl'] .cookiesModal.cookiesModal--actions,
html[dir='rtl'] .privacyModal.privacyModal--header {
    flex-direction: row-reverse;
}

html[dir='rtl'] .cookiesModal.cookiesModal--tabHeader,
html[dir='rtl'] .privacyModal.privacyModal--tabHeader {
    flex-direction: row-reverse;
}

.cookiesModal, .privacyModal {
  &--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &--logo {
    width: 213px;
    padding: 10px;
  }

  &--actions {
    padding: 40px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .Button--primary {
      margin-left: auto;
    }
  }

  &--link {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    border: none;
    background-color: transparent;

    &:hover,
    &:active {
      color: #a88838;
    }
  }

  &--tabHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }

  &--tableHeader {
    padding: 10px;
    border-bottom: 2px solid #82B547;
  }

  &--tabHeader h4,
  &--tabTable h4 {
    font-weight: bold;
  }

  &--tabTable .mat-table {
    max-height: 250px;
  }

  &--cookieSource:not(:first-of-type) {
    border-top: 1px solid #e0e0e0;
  }

  &--cookieSource {
    .mat-cell {
      font-weight: bold;
      font-size: 1rem;
    }
  }

  &--tabActions {
    .mat-radio-button {
      margin: 0 10px;
      padding: 10px;

      label {
        margin-bottom: 0;
      }
    }

    p {
      font-weight: 500 !important;
      color: lightgray;
      margin-bottom: 0;
    }
  }
}

html[dir='rtl'] .toolkit-cookies-preferences-modal .mat-tab-group,
html[dir='rtl'] .toolkit-cookies-preferences-modal .mat-radio-group
{
    flex-direction: row-reverse;
}


toolkit-cookies-preferences-modal {
  .mat-tab-group {
    display: flex;
    flex-direction: row;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }

  .mat-tab-group.mat-primary .mat-ink-bar {
    background-color: transparent;
  }

  .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-labels {
    flex-direction: column;

    .mat-tab-label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: lightgray;
      border-right: 1px solid gray;
      border-bottom: 1px solid gray;
      border-left: 1px solid gray;
    }

    .mat-tab-label-active {
      border-left: 10px solid #82B547;
      background-color: transparent;
      opacity: 1;
    }

    .mat-tab-label-content {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: bold;
      color: black;
    }
  }

  .mat-radio-group {
    display: flex;
    flex-direction: row;
  }

  .mat-tab-body-wrapper, .mat-tab-body {
    width: 100%;
    min-height: 400px;
  }

  .mat-tab-body-content {
    padding: 10px 0 10px 20px;
    height: 450px;
  }

  html[dir='rtl'] .mat-tab-body-content {
    padding: 10px 20px 10px 0;
  }

  .mat-table {
    max-height: 250px;
    overflow: auto;
    background-color: #fafafa;

    .mat-cell {
      padding-right: 10px;
      font-size: 0.8rem;
      min-width: 100px;
    }

    .mat-header-cell {
      padding-right: 10px;
      min-width: 100px;
    }

    .mat-cell:first-of-type,
    .mat-header-cell:first-of-type {
      padding-left: 10px;
      min-width: 190px;
    }

    .mat-row {
      border-bottom: none;
    }
  }
}

toolkit-privacy-policy-modal {
  section {
    padding: 0 10px !important;
  }
}

@media only screen and (max-width: 1026px) {
  .cookiesModal, .privacyModal {
    &--logo {
      width: 186px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .cookiesModal, .privacyModal {
    &--header {
      position: relative;
      flex-direction: column;
      align-items: flex-start;

      button {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &--actions {
      flex-direction: column;

      toolkit-privacy-policy-button {
        margin: 20px 0;
      }
    }
  }

  html[dir='rtl'] .toolkit-cookies-preferences-modal .mat-tab-labels,
  html[dir='rtl'] .toolkit-cookies-preferences-modal .mat-tab-labels {
      flex-direction: row-reverse;
  }

  toolkit-cookies-preferences-modal {
    .mat-tab-group {
      flex-direction: column;
    }

    .mat-tab-labels {
      flex-direction: row;
    }

    .mat-tab-body-content {
      padding: 10px 10px;
    }

    .mat-tab-labels .mat-tab-label {
      flex-grow: 1;
    }

    .mat-header-row {
      display: flex !important;
    }
    .mat-table {
      .mat-header-cell {
        min-width: 100px;
      }
      .mat-cell {
        font-size: 0.9rem;
        min-width: 100px;
      }
    }

    .mat-row {
      flex-direction: row !important;
    }
  }

  .cdk-overlay-pane {
    max-width: 100vw !important;
    height: 100vh !important;
  }
}

@media only screen and (max-width: 450px) {
  .cookiesModal, .privacyModal {
    &--tabHeader,
    &--actions {
      flex-direction: column;
    }

    &--link {
      padding: 10px;
    }
  }

  toolkit-cookies-preferences-modal {
    .PageContent-title {
      font-size: 1.35rem;
    }

    .mat-tab-body-content {
      padding: 10px 0;
    }

    mat-header-cell,
    mat-cell,
    mat-header-cell:first-of-type,
    mat-cell:first-of-type {
      padding-left: 10px;
    }

    mat-header-cell:last-of-type,
    mat-cell:last-of-type {
      padding-right: 10px;
    }
  }
}

html[dir='rtl'] .mat-dialog-container {
    direction: rtl;
}

toolkit-privacy-policy-modal {
  .Container, .PageContent {
    background: transparent;
  }

  .Container {
    margin: 0 !important;
    padding: 0;

    .col-lg-8, .col-md-10, .col-sm-12, .col-xs-12 {
      width: 100%;
    }
  }

  .mat-dialog-content {
    border-top: 1px grey solid;
  }
}

@media only screen and (max-width: 767px) {
  toolkit-privacy-policy-modal {
    .mat-dialog-content {
      max-height: 100%;
    }
  }
}

app-third-party {
  .PageContent-title {
    margin-bottom: 35px;
  }

  .third-party--date {
    margin-top: 50px;
  }

  .list li {
    padding: 5px 0;
  }
}

.NewActivityButton,
.NewActivityButton:active,
.NewActivityButton:hover {
  position: relative;
  border: 1px solid #82B547 !important;
  background-color: #82B547 !important;
  color: #ffffff !important;
}

.NewActivityButton:disabled:hover:after {
  content: attr(data-tooltip);
  position: absolute;
  left: 180px;
  top: -10px;
  width: 220px;
  margin-left: 5px;
  padding: 12px;
  color: #FFFFFF;
  background: #F2B816;
  border-radius: 3px;
}

@media only screen and (max-width: 767px) {
  .NewActivityButton:disabled:hover:after {
    left: -40px;
    top: 55px;
  }
}

.NewActivityButton:disabled {
  border: 1px solid #9E9E9E !important;
  background-color: #9E9E9E !important;
  color: #ffffff !important;
}

html[dir='rtl'] .ModalActivity.header {
    flex-direction: row-reverse;
}

.ModalActivity {
  &--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
  }

  &--request {
    padding: 0 40px;

    textarea.mat-input-element {
      border: 1px solid lightgray;
      padding: 15px;
    }

    .mat-dialog-content {
      margin: 0;
      padding: 0 0 0 15px;
    }

    .ddp-radio-group-column {
      padding: 0 0 0 10px;
    }
  }

  &--requestSubmitted {
    padding: 50px 40px 0 40px;

    .mat-dialog-content {
      padding: 0 100px 50px 100px;
      text-align: center;
    }

    .mat-icon {
      width: 60px;
      height: 60px;
    }

    .material-icons {
      display: block;
      margin: 0 auto;
      font-size: 45px;
      color: #82B547;
    }
  }

  &--submit,
  &--submit:active,
  &--submit:hover {
    position: relative;
    border: 1px solid #82B547 !important;
    background-color: #82B547 !important;
    color: #ffffff !important;
  }
}

@media only screen and (max-width: 450px) {
  .ModalActivity {
    &--header {
      padding-left: 0;
    }

    &--request {
      padding: 0;
    }

    &--requestSubmitted {
      p {
        padding: 0 10px 50px 10px;
      }
    }
  }
}

.CenterChild {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
}

ddp-popup-with-checkbox {
  .SimpleButton {
    margin-top: 0;
  }
}

.ddp-popup-with-checkbox {
  .SimpleButton {
    margin-top: 0;
  }

  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.1;
  }

  .popup {
    &--text {
      font-size: 0.8rem;
    }

    &--checkboxText {
      font-size: 0.8rem;
      margin: 0;
    }

    &--actions {
      display: flex;
      justify-content: space-between;

      button {
        padding: 5px 30px;
      }
    }
  }
}

@media only screen and (max-width: 824px) {
  .cdk-overlay-pane.ddp-popup-with-checkbox {
    margin-top: 150px !important;
  }
}

@media only screen and (max-width: 767px) {
  .cdk-overlay-pane.ddp-popup-with-checkbox {
    width: 300px !important;
    max-height: 170px !important;
    margin-top: 50% !important;
    margin-left: calc(50% - 150px) !important;
  }
}
